export const listColumns = [
  {
    title: '分组',
    scopedSlots: { customRender: 'groupName' },
  },
  {
    title: '包含媒体号',
    dataIndex: 'authorSize',
  },
  {
    title: '创建人',
    dataIndex: 'creator',
  },
  {
    title: '更新时间',
    dataIndex: 'mtime',
  },
  {
    title: '创建时间',
    dataIndex: 'ctime',
  },
  {
    align: 'center',
    title: '操作',
    scopedSlots: { customRender: 'operation' },
  },
];

export const detailsColumns = [
  {
    title: '媒体号',
    scopedSlots: { customRender: 'mediaAccount' },
  },
  {
    title: '所属成员',
    scopedSlots: { customRender: 'belongMember' },
  },
  {
    title: '品牌',
    dataIndex: 'principalName',
  },
  {
    title: '添加时间',
    dataIndex: 'ctime',
  },
  {
    align: 'center',
    title: '操作',
    scopedSlots: { customRender: 'operation' },
  },
];

export const personalityTypeList = [
  {
    label: '销售',
    value: 'SALE',
  },
  {
    label: '门店',
    value: 'STORE',
  },
];

export const personalityTypeObject = personalityTypeList.reduce((pre, cur) => ((pre[cur.value] = cur.label), pre), {});