<template>
  <div class="xhsMediaGroup">
    <div class="top">
      <template v-if="!isEdit">
        <a-space size="large">
          <span
            >包含<a-input-search
              placeholder="请输入媒体id/昵称"
              style="margin: 0 5px; width: 160px"
              v-model.trim="searchParams.xhsParam"
              @search="
                () => {
                  pagination.current = 1;
                  getDataList();
                }
              "
            ></a-input-search
            >的分组</span
          >
          <span
            ><a-input-search
              placeholder="分组名称"
              style="width: 160px"
              v-model.trim="searchParams.groupName"
              @search="
                () => {
                  pagination.current = 1;
                  getDataList();
                }
              "
            ></a-input-search
          ></span>
        </a-space>
      </template>
      <template v-if="isEdit">
        <a-space :size="80">
          <span style="font-size: 18px; font-weight: bold">
            <span v-show="!showChangeName">{{ groupDetails.groupName || '-' }}</span>
            <span v-show="showChangeName"
              ><a-input style="width: 160px" v-model.trim="editGroupName"></a-input
              ><a-button type="primary" style="margin: 0 10px" :loading="editLoading" @click="handleChangeName"
                >确认</a-button
              ><a-button
                @click="
                  () => {
                    showChangeName = false;
                    editGroupName = groupDetails.groupName;
                  }
                "
                >取消</a-button
              ></span
            >
            <a-dropdown :trigger="['hover']">
              <a-icon
                type="form"
                style="margin-left: 5px"
              />
              <a-menu slot="overlay">
                <a-menu-item>
                  <a-button
                    type="link"
                    @click="
                      () => {
                        showChangeName = true;
                        editGroupName = groupDetails.groupName;
                      }
                    "
                    >重命名</a-button
                  >
                </a-menu-item>
                <a-menu-item>
                  <a-popconfirm
                    placement="rightTop"
                    title="确定删除该分组？"
                    ok-text="确定"
                    cancel-text="取消"
                    @confirm="handleGroupDel()"
                  >
                    <a-button type="link">删除</a-button>
                  </a-popconfirm>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
          <span>创建人：{{ groupDetails.creator }}</span>
          <span>更新时间：{{ groupDetails.mtime }}</span>
        </a-space>
      </template>
    </div>
    <div class="bottom">
      <template v-if="!isEdit">
        <div style="text-align: right"><a-button type="primary" @click="showAddGroup = true">新建分组</a-button></div>
      </template>
      <template v-if="isEdit">
        <div style="text-align: right"><a-button type="primary" @click="showAddMedia = true">添加</a-button></div>
        <div class="table-line line-flex" v-show="selectedRowKeys.length">
          <div class="line-flex">
            <div>
              已选<span style="margin: 0 2px; color: #378ffd">{{ selectedRowKeys.length }}</span
              >项
            </div>
            <a-button type="link" @click="selectedRowKeys = []">清空</a-button>

            <a-popconfirm title="确定删除?" ok-text="确定" cancel-text="取消" @confirm="handleMediaEdit('batch')">
              <a-button style="margin-left: 30px" :loading="tableLoading">批量删除</a-button>
            </a-popconfirm>
          </div>
        </div>
      </template>
      <a-table
        style="margin-top: 10px; width: 100%"
        :loading="tableLoading"
        :columns="columns"
        :data-source="dataList"
        :rowKey="(record) => record.id"
        :row-selection="rowSelection"
        :pagination="false"
      >
        <div slot="groupName" slot-scope="text, record">
          <div>{{ record.groupName }}</div>
          <div
            style="cursor: pointer"
            v-clipboard:copy="record.id"
            v-clipboard:success="onCopySuccess"
            v-clipboard:error="onCopyError"
          >
            <span style="margin-right: 10px; color: #878787">ID：{{ record.id }}</span>
            <a-icon type="copy" />
          </div>
        </div>
        <div class="media-column" slot="mediaAccount" slot-scope="text, record">
          <div class="media-left" v-show="record.avatar">
            <img :src="record.avatar" alt="头像" referrerpolicy="no-referrer" />
          </div>
          <div class="media-right">
            <span style="margin-left: 15px" class="blue-text" @click="handleJumpXHS(record)">{{
              record.nickname
            }}</span>
            <div class="line-flex right-txt">
              <div class="logo" v-show="record.code">
                <img src="@/assets/icon/xhs_logo.png" alt="" />
              </div>
              <div>{{ record.code || '-' }}</div>
              <div class="logo">
                <img v-show="record.photoUrl" :src="record.photoUrl" alt="" referrerpolicy="no-referrer" />
              </div>
              <div>{{ personalityTypeObject[record.personalityType] }}</div>
            </div>
          </div>
        </div>
        <div style="text-align: left" slot="belongMember" slot-scope="text, record">
          <span>
            名称：
            <span v-if="record.matrixMemberName" class="blue-text" @click="handleDetailJump(record)">{{
              record.matrixMemberName
            }}</span>
            <span v-else> - </span>
          </span>
          <div>
            编号：<span>{{ record.matrixMemberCode || '-' }}</span>
          </div>
        </div>
        <div slot="operation" slot-scope="text, record">
          <template v-if="!isEdit">
            <a-space>
              <a-button type="link" @click="handleJumpDetail(record.id)">详情</a-button>
              <a-popconfirm
                placement="topLeft"
                title="确定删除？"
                ok-text="确定"
                cancel-text="取消"
                @confirm="handleDelGroup(record.id)"
              >
                <a-button type="link">删除</a-button>
              </a-popconfirm>
            </a-space>
          </template>
          <template v-if="isEdit">
            <a-popconfirm
              placement="topLeft"
              title="确定删除？"
              ok-text="确定"
              cancel-text="取消"
              @confirm="handleMediaEdit('single', record)"
            >
              <a-button type="link">从分组删除</a-button>
            </a-popconfirm>
          </template>
        </div>
      </a-table>
      <base-pagination
        :currentPage="pagination.current"
        :options="['15', '30', '50']"
        :pageSize="pagination.pageSize"
        :total="pagination.total"
        @onChange="handlePaginationChange"
        @onShowSizeChange="handlePaginationChange"
      />
    </div>

    <!-- 新建分组弹窗 -->
    <a-modal width="600px" title="新建分组" :visible="showAddGroup" @close="handleAddClose" @cancel="handleAddClose">
      <div>
        <span style="margin-right: 20px"><span style="color: #da0722">*</span>分组名称：</span>
        <a-input style="width: 60%" :maxLength="30" v-model.trim="addGroupName"></a-input>
      </div>
      <template slot="footer">
        <a-button key="back" @click="handleAddClose"> 取消 </a-button>
        <a-button
          key="submit"
          type="primary"
          :disabled="!(addGroupName && addGroupName.trim())"
          :loading="addLoading"
          @click="handleAddOk"
        >
          确定
        </a-button>
      </template>
    </a-modal>

    <!-- 添加媒体号弹窗 -->
    <a-modal
      width="600px"
      :visible="showAddMedia"
      @close="handleAddMediaClose"
      @cancel="handleAddMediaClose"
      :zIndex="1"
    >
      <div slot="title">媒体号加入分组<span class="sub-title"></span></div>
      <div style="margin-bottom: 15px; color: #6e6e6e">媒体号将同步添加至代运营媒体号列表</div>
      <div style="display: flex">
        <div style="width: 100px"><span style="color: #f00">*</span> 小红书号：</div>
        <a-textarea
          v-model="addMediaList"
          placeholder="请输入小红书媒体ID, 英文逗号或者回车分割(最多输入500个字)"
          :auto-size="{ minRows: 6, maxRows: 8 }"
          :maxLength="500"
        />
      </div>
      <template slot="footer">
        <a-button @click="handleAddMediaClose"> 取消 </a-button>
        <a-button key="submit" type="primary" :loading="addMediaLoading" @click="handleAddMediaOk"> 确定 </a-button>
      </template>
    </a-modal>
    <!-- 添加失败弹窗 -->
    <a-modal
      width="700px"
      :dialog-style="{ top: '200px' }"
      :visible="showErrModal"
      @close="handleHideFail"
      @cancel="handleHideFail"
      :zIndex="2"
      :footer="null"
    >
      <template slot="title">
        <a-icon type="close-circle" style="color: #f00; padding-right: 10px; font-size: 20px" />以下媒体号添加失败
      </template>
      <div class="fail-list">
        <div class="fail-list-item" v-for="(val, index) in failList" :key="index">{{ val }}：未收录或ID错误</div>
      </div>
      <a-button type="link" id="copy_text" :data-clipboard-text="failList" @click="handleOkCopyUrl">复制</a-button>
    </a-modal>
  </div>
</template>

<script>
import { listColumns, detailsColumns, personalityTypeObject } from './data';
import Clipboard from 'clipboard';
import api from '@/api/xhsAgencyApi';

export default {
  name: 'xhsMediaGroup',
  data() {
    return {
      personalityTypeObject,
      isEdit: false,
      showChangeName: false,
      tableLoading: false,
      dataList: [],
      selectedRowKeys: [],
      searchParams: {
        xhsParam: undefined,
        groupName: undefined,
      },
      pagination: {
        current: 1,
        pageSize: 15,
        total: 1,
        totalCitationCount: 0,
      },
      showAddGroup: false,
      addGroupName: undefined,
      addLoading: false,
      showAddMedia: false,
      addMediaLoading: false,
      addMediaList: undefined,
      showErrModal: false,
      failList: [],
      groupDetails: {
        groupName: undefined,
        id: undefined,
        mtime: undefined,
        creator: undefined,
      },
      editGroupName: undefined,
      editLoading: false,
    };
  },
  computed: {
    columns() {
      let columns = [];
      switch (!this.isEdit) {
        case true: // 分组列表
          columns = listColumns;
          break;
        case false: // 运营爆文
          columns = detailsColumns;
          break;
        default:
          break;
      }
      return columns;
    },
    rowSelection() {
      const { selectedRowKeys } = this;
      return this.isEdit
        ? {
            selectedRowKeys,
            onChange: this.onSelectChange,
          }
        : null;
    },
  },
  mounted() {
    this.groupId = this.$route.query.groupId;
    if (this.groupId) {
      this.isEdit = true;
      this.initEdit();
    } else {
      this.getDataList();
    }
  },
  methods: {
    getDataList() {
      this.tableLoading = true;
      const params = {
        pageNum: this.pagination.current,
        pageSize: this.pagination.pageSize,
        groupScene: 6,
        xhsParam: this.searchParams.xhsParam || null,
        groupName: this.searchParams.groupName || null,
      };
      this.handleRequest(
        'getMediaGroupList',
        (data) => {
          this.pagination.total = data.total;
          this.dataList = data.list;
        },
        params
      ).finally(() => (this.tableLoading = false));
    },
    initEdit() {
      this.pagination.current = 1;
      this.pagination.pageSize = 15;
      this.getGroupDetail();
      this.getMediaList();
    },
    getGroupDetail() {
      this.handleRequest(
        'getGroupDetail',
        (data) => {
          this.groupDetails = data;
        },
        { id: this.$route.query.groupId }
      );
    },
    getMediaList() {
      this.tableLoading = true;
      const params = {
        groupId: this.$route.query.groupId,
        pageNum: this.pagination.current,
        pageSize: this.pagination.pageSize,
        groupScene: 6,
      };
      this.handleRequest(
        'getGroupMediaList',
        (data) => {
          this.pagination.total = data.total;
          this.dataList = data.list;
        },
        params
      ).finally(() => (this.tableLoading = false));
    },
    handleAddMediaClose() {
      this.showAddMedia = false;
      this.addMediaList = '';
    },
    handleAddMediaOk() {
      if (!this.addMediaList) return this.$message.error('添加账号不得为空');
      const reg = '\n';
      const strArr = this.addMediaList.replace(new RegExp(reg, 'gm'), ',').split(',');
      const authorCodes = strArr.map((val) => val.trim()).filter((value) => value);
      const params = {
        authorCodes,
        groupIds: [this.$route.query.groupId],
        masterAccountName: this.$router.app.user.name,
        masterAccountUserId: this.$router.app.user.id,
        masterAccountUsername: this.$router.app.user.username,
      };
      this.addMediaLoading = true;
      this.handleRequest(
        'patchMediaInGroup',
        (data) => {
          if (data.failList.length) {
            this.failList = [];
            this.showError(data.failList);
          } else {
            this.$message.success('全部添加成功');
          }
          this.getMediaList();
        },
        params
      ).finally(() => {
        this.showAddMedia = false;
        this.addMediaLoading = false;
        this.addMediaList = '';
      });
    },
    handleChangeName() {
      this.editLoading = true;
      this.handleRequest(
        'editGroupDetail',
        () => {
          this.$message.success('修改成功');
          this.getGroupDetail();
          this.showChangeName = false;
        },
        {
          id: this.$route.query.groupId,
          groupName: this.editGroupName,
        }
      ).finally(() => (this.editLoading = false));
    },
    showError(ErrorList) {
      this.failList = ErrorList;
      this.showErrModal = true;
    },
    handleHideFail() {
      this.failList = [];
      this.showErrModal = false;
    },
    // 复制账号
    handleOkCopyUrl() {
      const clipboard = new Clipboard('#copy_text');
      clipboard.on('success', () => {
        this.$message.success('复制成功');
        clipboard.destroy(); // 释放内存
      });
      clipboard.on('error', () => {
        // 不支持复制
        this.$message.error('该浏览器不支持自动复制');
        clipboard.destroy(); // 释放内存
      });
      this.showErrModal = false;
    },
    handleAddClose() {
      this.showAddGroup = false;
      this.addGroupName = undefined;
    },
    handleAddOk() {
      if (!this.addGroupName) return this.$message.info('请填写分组名称');
      this.addLoading = true;
      this.handleRequest(
        'addMediaGroup',
        () => {
          this.$message.success('新增成功');
          this.handleAddClose();
          this.getDataList();
        },
        { groupName: this.addGroupName, groupScene: 6, groupType: 2, isPublish: 2, status: 2 }
      ).finally(() => (this.addLoading = false));
    },
    handlePaginationChange(current, pageSize) {
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      if (this.isEdit) {
        this.getMediaList();
      } else {
        this.getDataList();
      }
    },
    handleJumpDetail(id) {
      let routeData = this.$router.resolve({
        name: 'xhsMediaGroup',
        query: { groupId: id },
      });
      window.open(routeData.href, '_blank');
    },
    // 小红书主页跳转
    async handleJumpXHS(value) {
      const hide = this.$message.loading('查询中...', 0);
      await this.handleRequest(
        'getXhsAccountPage',
        (data) => {
          window.open(data, '_blank');
        },
        {
          authorId: value.authorId,
        }
      ).finally(() => hide());
    },
    handleMediaEdit(type, data) {
      let ids = undefined;
      if (type == 'batch') {
        ids = this.selectedRowKeys.join(',');
      } else {
        ids = data.id;
      }
      this.handleRequest(
        'removeMeidaFromGroup',
        () => {
          this.$message.success('操作成功');
          this.selectedRowKeys = [];
          this.initEdit();
        },
        { ids }
      );
      console.log(ids);
    },
    handleDelGroup(id) {
      this.handleRequest(
        'deleteMediaGroup',
        () => {
          this.$message.success('操作成功');
          if (this.pagination.current > 1 && this.pagination.total === 1) this.pagination.current--;
          this.getDataList();
        },
        { id }
      );
    },
    handleGroupDel() {
      this.handleRequest(
        'deleteMediaGroup',
        () => {
          this.$router.replace({ name: 'xhsMediaGroup' }, () => {
            this.pagination.current = 1;
            this.pagination.pageSize = 15;
            this.isEdit = false;
            this.getDataList();
          });
        },
        { id: this.$route.query.groupId }
      );
    },
    // 经销商跳转
    async handleDetailJump(record) {
      const hide = this.$message.loading('查询中...', 0);
      await this.handleRequest(
        'getDealerDetail',
        (data) => {
          const routeUrl = this.$router.resolve({
            name: 'dealerDetail',
            query: {
              dealer_id: data.dealerId,
              dealer_name: record.matrixMemberName,
            },
          });
          window.open(routeUrl.href, '_blank');
        },
        {
          matrixMemberId: record.matrixMemberId,
          principalId: record.principalId,
        }
      ).finally(() => hide());
    },
    async handleRequest(APINAME, callbackFn, PARAMS = null) {
      const { code, data, message } = await api[APINAME](PARAMS);
      if (code === 200) {
        callbackFn(data);
      } else {
        return this.$message.error(`${message}`);
      }
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    onCopySuccess() {
      this.$message.success('已复制ID');
    },
    onCopyError() {
      this.$message.error('复制失败');
    },
  },
};
</script>

<style lang="less" scoped>
.xhsMediaGroup {
  padding: 10px;
}
.top {
  padding: 10px;
  margin-bottom: 10px;
  background: #fff;
}

.bottom {
  padding: 10px;
  background: #fff;
}

.media-column {
  display: flex;
  margin-top: 10px;
  text-align: left;

  .media-left {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }
  .avatar-left {
    width: 50px;
    height: 50px;
    margin-right: 15px;
    overflow: hidden;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .media-right {
    flex: 1;

    .right-txt {
      justify-content: start;

      .logo {
        width: 20px;
        height: 20px;
        border-radius: 10px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .avatar-right {
    flex: 1;
    display: flex;
    align-items: center;
  }
}

.table-line {
  margin: 10px 0;
  padding: 5px 20px;
  border-radius: 5px;
  background-color: #e6f7ff;
  border: 1px solid #8fc9ff;
}

.line-flex {
  display: flex;
  align-items: center;
}

.blue-text {
  color: #1890ff;
  cursor: pointer;
}
</style>
